import { Component, OnInit } from '@angular/core';
import { createCipheriv } from 'crypto';
import { Observable } from 'rxjs';
import { MonerisCheckout } from 'src/app/common/models/monerisCheckout';
import { CCDetails } from 'src/app/common/models/paymentOptions';
import { MonerisReceiptData } from 'src/app/models/obpp-invoice.model';
import { MonerisIntegrationService } from 'src/app/services/moneris-integration/moneris-integration.service';

@Component({
  selector: 'app-moneris-integration',
  templateUrl: './moneris-integration.component.html',
  styleUrls: ['./moneris-integration.component.css']
})
export class MonerisIntegrationComponent implements OnInit {

  responseCodeDecider: number = 50;

  constructor(private monerisIntegrationService: MonerisIntegrationService) { }



  ngOnInit(): void {
  }

  public monerisPreloadRequest(monerisPayload: MonerisCheckout): Observable<any> {
    return this.monerisIntegrationService.monerisPreloadRequest(monerisPayload);
  }    

  public monerisReceiptRequest(ticketId: any, currency: string, invoiceList: any, userEmail: any, createMethod: any, alternativeEmail: string, monerisEnv: string): Observable<any> { 
    return this.monerisIntegrationService.monerisReceiptRequest(ticketId, currency, invoiceList, userEmail, createMethod, alternativeEmail, monerisEnv);
  }

  public monerisReceiptHandling(monerisResponse: any, invoiceList: any, email: string): MonerisReceiptData {
    // In case that an error happens during the payment process we need to look at the response_code
    // According to the Moneris documentation, if the response_code is greater than 50, then the payment was declined
    // So we return a true flag, in order to trigger the error message on the UI and send the info to the BE
    const responseCode = Number(monerisResponse?.response?.receipt?.cc?.response_code);

    if (responseCode > this.responseCodeDecider) {

      // missing the code for the service, which will send info to BE 
      // this.monerisIntegrationService.monerisErrorHandling(errorCode);

      return {
        paymentSuccess: false,
      } as MonerisReceiptData ;

    } else {

      const transactionDate = new Date(monerisResponse.response.receipt.cc.transaction_date_time);
      const paymentDate = transactionDate.toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      });

      const invoicesChecked = invoiceList.filter((item: any) => item.isChecked === true);
      return {
        paymentSuccess: true,
        ccNumber: monerisResponse.response.receipt.cc.tokenize.first4last4,
        ccType: monerisResponse.response.receipt.cc.card_type,
        referenceNumber: monerisResponse.response.receipt.cc.reference_no,
        paymentDate: paymentDate ? paymentDate : '',
        totalPayAmount: monerisResponse.response.receipt.cc.amount,
        emailReceipt: email,
        invoiceList: invoicesChecked.length > 0? invoicesChecked : []
      } as MonerisReceiptData ;
    }
  }

  public monerisUpdateInvoicesDataRequest(userId: any, createMethod: string, ticketId: string, invoicesList: any, currency: string, monerisEnv: string): Observable<any> { 
    const invoicesChecked = invoicesList.filter((item: any) => item.isChecked === true);
    return this.monerisIntegrationService.monerisUpdateInvoicesDataRequest(userId, createMethod, ticketId, invoicesChecked, currency, monerisEnv);
  }


  public cpsaReceiptHandling(obppResponse: any, invoiceList: any) {
    const cpsaReceiptData: { paymentSuccess: boolean; ccNumber?: any; ccType?: any; referenceNumber?: any; paymentDate?: string; totalPayAmount?: any; invoiceNumber?: any; }[] = [];
    obppResponse.forEach((res: any) => {
      if (res.transactionResult != "Approved") {
        cpsaReceiptData.push({
          paymentSuccess: false
        })
      }else {
        const transactionDate = new Date(res.transactionDate + " " + res.transactionTime);  
        const paymentDate = transactionDate.toLocaleDateString('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        });  

        cpsaReceiptData.push({
          paymentSuccess: true,
          ccNumber: res.ccNumber,
          ccType: res.ccType,
          referenceNumber: res.referenceNo,
          paymentDate: paymentDate ? paymentDate : '',
          totalPayAmount: res.transactionAmount,
          invoiceNumber: res.invoiceNumber
        });
      }
      return cpsaReceiptData;
    })
  }
}
