<div>
	<div class="modal-header" [ngClass]="modalType == 'error' ? 'error' : modalType == 'warning' ? 'warning' : 'success'">
		<h4 class="modal-title">{{'SEND_TEMP_PASSWORD_WARNING_TITLE' | translate}}</h4>
	</div>
		
	<div class="modal-footer text-center">
	<div class="alert alert-danger" role="alert" *ngIf="displaySendPasswordErrorMsgBlock && displaySendPasswordErrorMsgList.length>0">
     <div class="container">
           <div class="col-xs-6 error-padding-left">
           <ul><li *ngFor="let errMsg of displaySendPasswordErrorMsgList">{{errMsg  | translate}}</li></ul>
            </div>
      </div> 	
      </div>
		<label class="txt-puro-red"></label>
		                   
	 	<p>{{'SEND_TEMP_PASSWORD_WARNING_TEXT' | translate}}  </p>                        
	</div>
	<div class="modal-footer d-flex justify-content-center">
		<button  class="btn btn-primary btn-block primary-btn" (click)="confirmSendTempPassword()">{{ 'YES' | translate }}</button>
		<button   class="btn btn-primary cta-blue info-btn" (click)="closeSendTempPasswordModal()">{{ 'NO' | translate }}</button>
	</div> 
</div>