<div>
    <div class="modal-header d-flex justify-content-center"
        [ngClass]="modalType == 'error' ? 'error' : modalType == 'warning' ? 'warning' : 'success'">
        <h4 class="modal-title  text-center">{{'DELETE_USER_TITLE_TEXT' | translate}}</h4>
    </div>
    <div class="modal-body text-center">
        {{'DELETE_USER_CONFIRM_TEXT' | translate}}
        <div class="alert" role="alert" *ngIf="displayErrorMsgBlock">
            <div class="container">
                <div class="col-xs-6 error">
                    <ul>
                        <li>{{errMsg | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>{{'DELETE_USER_REASON_TEXT' | translate}}:</label>
            <textarea class="form-control" rows="3" #deleteJustification></textarea>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button class="btn btn-primary btn-block primary-btn" style="margin-bottom : 10px;" (click)="confirmUserDelete()">{{'DELETE_USER_TEXT'
            | translate}}</button>
        <button class="btn btn-primary cta-blue info-btn" style="margin-bottom : 10px;" (click)="cancelUserDelete()">{{'CANCEL' |
            translate}}</button>
    </div>
</div>