import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServicesDataConfiguration } from 'src/app/data-config/services-data-config';

@Component({
  selector: 'app-check-terms-condition',
  templateUrl: './check-terms-condition.component.html',
  styleUrls: ['./check-terms-condition.component.css']
})
export class CheckTermsConditionComponent implements OnInit {

  @Input() labelFirstPart?: string;
  @Input() textLink?: string;
  @Input() labelLastPart?: string;
  @Input() urlPdf?: string;
  @Input() error?: string;
  @Input() showError?: boolean = true;
  @Input() disabled?: boolean = false;
  @Input() isCheckboxChecked?: boolean = false;
  @Output() checkboxChange = new EventEmitter<boolean>();

  folderPath = 'assets/documents/';
  successResponse = 'success';
  httpEndpoint = 'http:';
  httpsEndpoint = 'https:';
  urlLanguageParam = 'LANG_AUTH';
  servicesDataConfiguration = new ServicesDataConfiguration();

  constructor( ) { }

  onCheckboxChange(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.checkboxChange.emit(checked);
    this.isCheckboxChecked = checked;
    if(this.isCheckboxChecked){
      this.showError = false;
    } else {
      this.showError = true;
    }
  }

  setShowError(status: boolean) {
    this.showError = status;
  }

  ngOnInit(): void { }

}