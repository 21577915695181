<div class="modal-header" [ngClass]="modalType == 'error' ? 'error' : modalType == 'warning' ? 'warning' : 'success'">
    <h3 class="modal-title w-100 text-center">{{my_modal_title | translate}}</h3>
</div>
<div class="modal-body">
    <p> {{my_modal_content | translate}}</p>
    <div class="alert alert-danger" role="alert" *ngIf="displayErrorMsgBlock">
        <div class="container">
            <div class="col-xs-6 error-padding-left" *ngFor="let err of errMsg">
                <p>{{err | translate}}</p>
                
            </div>
        </div>
    </div>
    <form [formGroup]="changeForm">
        <div class="form-group row">
            <label for="currentPassword" class="col-sm-4 col-form-label">{{'PASSWORD_CURRENT' | translate}}
            </label>
            <div class="col-sm-6">
                <div class="input-group">
                    <div class="input-group-addon">
                        <img src="assets/images/icon-textfield-password.png" alt="currentPassword">
                    </div>
                    <input name="currentPassword" type="password" class="form-control" required="required" minlength="8"
                        formControlName="currentPassword" maxlength="25" tabindex="0"/>
                    <div class="setPasswordErrMsg"
                        *ngIf="changeForm.get('currentPassword')?.invalid && changeForm.get('currentPassword')?.dirty">
                        <span class="error-block">{{'PASSWORD_ERR' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="newPassword" class="col-sm-4 col-form-label">{{'PASSWORD_NEW' |translate}}&nbsp;&nbsp;&nbsp;&nbsp;
                <br /><small>{{'PASSWORD_MSG' | translate}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small></label>
            <div class="col-sm-6">
                <div class="input-group">
                    <div class="input-group-addon">
                        <img src="assets/images/icon-textfield-password.png" alt="newPassowrd">
                    </div>
                    <input name="newPassword" type="password" class="form-control" required="required"
                        formControlName="newPassword" maxlength="25" maxlength="25"  tabindex="0"/>
                    <div class="setPasswordErrMsg"
                        *ngIf="changeForm.get('newPassword')?.invalid && changeForm.get('newPassword')?.dirty">
                        <span class="error-block">{{'PASSWORD_ERR' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="confirmPassword" class="col-sm-4 col-form-label">{{'PASSWORD_CONFIRM' | translate}}</label>
            <div class="col-sm-6">
                <div class="input-group">
                    <div class="input-group-addon">
                        <img src="assets/images/icon-textfield-password.png" alt="confirmPassword">
                    </div>
                    <input name="confirmPassword" type="password" class="form-control" autocomplete="off"
                        formControlName="confirmPassword" required="required" minlength="8" maxlength="25" tabindex="0"/>
                    <div class="setPasswordErrMsg"
                        *ngIf="changeForm.get('confirmPassword')?.invalid && changeForm.get('confirmPassword')?.dirty">
                        <span class="error-block">{{'PASSWORD_ERR' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer d-flex justify-content-center">
    <div class="col-sm-6 m-0 btn-bar">
        <button [disabled]="changeForm.invalid" type="button" *ngIf="firstButtonLabel" class="btn btn-primary btn-block primary-btn"
            (click)="successActionFunc(changeForm)">{{firstButtonLabel | translate}}</button>
        <button type="button" *ngIf="secondButtonLabel" class="btn btn-primary cta-blue info-btn sec-btn"
            (click)="cancelActionFunc()">{{secondButtonLabel | translate}}</button>
    </div>
</div>
