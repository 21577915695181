import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CreditCardsType, PaymentOption, PaymentOptions, CCDetails } from '../../models/paymentOptions';
import { SelectPaymentOptionService } from 'src/app/services/select-payment-option/select-payment-option.service';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';
import { MonerisCheckout, ShoppingCartItem } from '../../models/monerisCheckout';
import { LocalStorageService } from 'src/app/services/global/local-storage.service';
import { MonerisIntegrationComponent } from '../moneris-integration/moneris-integration/moneris-integration.component';
import { AUPaymentSuccess, EFTData, EftReceiptData, MonerisReceiptData } from 'src/app/models/obpp-invoice.model';
import { EftRemittance } from '../../models/eftRemittance';
import { TranslateService } from '@ngx-translate/core';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';
import { EftPaymentService } from 'src/app/services/eft-payment/eft-payment.service';
import { MonerisIntegrationService } from 'src/app/services/moneris-integration/moneris-integration.service';
import { response } from 'express';
  
@Component({
  selector: 'app-select-payment-options',
  templateUrl: './select-payment-options.component.html',
  styleUrls: ['./select-payment-options.component.css']
})
export class SelectPaymentOptionsComponent implements OnInit, OnDestroy {

  @Input() invoiceList: any = [];
  @Input() totalAmount: number = 0;
  @Input() userEmail: string = '';
  @Input() alternativeEmailHandler: [boolean, string] = [false, ''];
  @Input() currentCurrency: string = '';

  @Output() cancelButtonIsClicked = new EventEmitter<boolean>();
  @Output() paymentSuccessFlag = new EventEmitter<AUPaymentSuccess>();
  @Output() paymentFailureFlag = new EventEmitter<boolean>();
  @Output() isMonerisIframeRendered = new EventEmitter<boolean>();

  paymentOptions: PaymentOptions[] = [];
  ccOptions: any = [
    {
      path: 'assets/images/icon-cc-visa.png'
    },
    {
      path: 'assets/images/icon-cc-mastercard.png'
    },
    {
      path: 'assets/images/icon-cc-amex.png'
    }
  ];

  monerisTicketId: string = '';
  receipt!: MonerisReceiptData;
  receiptEft!: EftReceiptData;
  optionSelected: boolean = false;
  eftErrorState: boolean = false;
  errorState: boolean = true;
  showEFTFrame: boolean = false;
  eftPaymentDate: string | undefined | string;
  eftPayerName: string = '';
  eftReferenceNbr: string = '';
  emailReceipt: string = '';
  eftLabel: string = 'EFT';

  selectedPaymentOption: PaymentOption = PaymentOption.SAVED_CREDIT_CARD;
  payWithMoneris: PaymentOption = PaymentOption.MONERIS;
  payWithEFT: PaymentOption = PaymentOption.EFT_REMITTANCE;
  payWithSavedCreditCard: PaymentOption = PaymentOption.SAVED_CREDIT_CARD;
  selectedSavedCard: CCDetails | null  = null;

  errorLoadingMonerisIframe: boolean = false;
  loadText: string = '';
  mcoIframeLoaded: boolean = false;
  termsAndConditionsAgree: boolean = false;

  showCompleteIframe: boolean = true;
  selectedInvoiceList: any = [];
  monerisEnv: string = '';

  constructor(
    private selectPaymentOptionService: SelectPaymentOptionService,
    private obppLoginService: OBPPLoginService,
    private dataSharingService: DataSharingService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private monerisIntegrationService: MonerisIntegrationService,
    public monerisComponent: MonerisIntegrationComponent,
    private eftService: EftPaymentService
  ) { 
    this.translateService.get('MESSAGE_LOADING').subscribe((translation: string) => {
      this.loadText = translation;
    })
  }

  ngOnInit(): void {
    this.emailReceipt = this.obppLoginService.getUserName();
    this.monerisIntegrationService.getMonerisEnvironment().subscribe({
      next: (env: any) => { 
        this.monerisEnv = env.response;
      },
      error: (error: any) => { 
        this.monerisEnv = 'prod';
      }
    });
    this.getSavedCreditCardDetails();

  }

    // reusing the same method from the SelectPaymentOptionService to get saved credit card info
  getSavedCreditCardDetails() { 
    this.dataSharingService.IsLoadingEnabled.next(true);

    this.selectPaymentOptionService.getCreditCardDetails(this.emailReceipt).subscribe({
      next: (creditData: any) => {

        const creditCardDetails = creditData?.serviceResponse?.object;


        if (creditCardDetails) {
          creditCardDetails.map((card: any) => {
            if (card.creditCardstatus === 'ACTIVE') { 
              this.paymentOptions.push({
                ccDetails: {
                  ccMask: card.ccMask.slice(-8),
                  ccType: card.ccType,
                  creditCardstatus: card.creditCardstatus,
                  ccExpiry: card.ccExpiry,
                  ccToken: card.ccToken,
                  ccPayerName: card.cardHolderName,
                },
                paymentOption: PaymentOption.SAVED_CREDIT_CARD
              });
            }
          });
        }

        this.paymentOptions.push({
          placeholder: 'PAY_WITH_ANOTHER_CC',
          paymentOption: PaymentOption.MONERIS,
        },
        {
          placeholder: 'EFT Remittance',
          paymentOption: PaymentOption.EFT_REMITTANCE,
        });
      }
    });

    this.dataSharingService.IsLoadingEnabled.next(false);


  }

  // handler function for selected payment method
  selectedPaymentOptionHandler(payOpt: PaymentOption, ccDetails: CCDetails | null) {
    this.errorState = true;
    this.selectedPaymentOption = payOpt;
    this.optionSelected = true;
    this.errorLoadingMonerisIframe = false;
    this.selectedSavedCard = ccDetails;

    switch (payOpt) {
      case PaymentOption.SAVED_CREDIT_CARD:
        this.eftErrorState = false;
      // do stuff
        break;
      case PaymentOption.MONERIS:
        this.updateMessageLoading();
        this.dataSharingService.IsLoadingEnabled.next(true);
        this.eftErrorState = false;
        this.initMonerisPreload();
        break;  
      case PaymentOption.EFT_REMITTANCE:
      // do stuff
        break;  
     }
  }

  // Insert CC logos 
  handleCreditCardLogo(ccType: string | undefined): string {
    if (ccType === CreditCardsType.VISA) {
      return this.ccOptions[0].path;

    } else if (ccType === CreditCardsType.MASTERCARD) {
      return this.ccOptions[1].path;

    } else if (ccType === CreditCardsType.AMEX) {
      return this.ccOptions[2].path;
    }

    return '';
  }

  convertCreditCardType(ccType: string | undefined): string {
    if (ccType === CreditCardsType.VISA) {
      return "V";

    } else if (ccType === CreditCardsType.MASTERCARD) {
      return "M";

    } else if (ccType === CreditCardsType.AMEX) {
      return "AX";
    }

    return '';
  }

  // MCO preload request
  initMonerisPreload() {
    this.updateMessageLoading();
    this.dataSharingService.IsLoadingEnabled.next(true);

    const monerisPayload: MonerisCheckout = {
      txn_total: this.totalAmount.toFixed(2).toString(),
      environment: this.monerisEnv,
      language: this.localStorageService.get('lang'),
      contact_details: {
        email: this.userEmail,
      },
      cart: {
        items: this.filterInvoiceList(this.invoiceList),
      },
      currency: this.currentCurrency,
    };

    this.monerisComponent.monerisPreloadRequest(monerisPayload).subscribe({
      next: (response) => {
        if (response.response.success === 'false') {
          this.errorLoadingMonerisIframe = true;
          this.dataSharingService.IsLoadingEnabled.next(false);
          this.dataSharingService.LoadingLabelText.next('');
        }

        if (response.response.ticket) {
          this.monerisTicketId = response.response.ticket;
          this.initMonerisIframe(response.response.ticket);
        }
       },
      error: (error) => { 
        console.error('Error on moneris preload request: ', error);
        this.errorLoadingMonerisIframe = true;
        this.dataSharingService.IsLoadingEnabled.next(false);
        this.dataSharingService.LoadingLabelText.next(''); 
      } 
    });
  }

  filterInvoiceList(invoiceList: any): ShoppingCartItem[] {
    const cartItem: ShoppingCartItem[] = [];

    invoiceList.forEach((item: any) => {
        if (item) {
          cartItem.push({
            unit_cost: item.paymentAmount,
            quantity: '1',
            description: "{" + 
              "\"account\":\"" + item.accountNumber + 
              "\",\"invoice\":\"" + item.invoiceNumber +
              "\",\"currency\":\"" + this.currentCurrency +
            "\"}"
          });
        }
    });
    
    return cartItem;
  }

  // MCO iframe initialization
  initMonerisIframe(ticketId: any) {

    // Configure Moneris Checkout
    const checkoutIframe = new monerisCheckout();

    // FIX ME: Should be changged to PROD before the deployment to PROD env.
    checkoutIframe.setMode(this.monerisEnv); 

    checkoutIframe.setCheckoutDiv('monerisCheckout', {
      ticketId,
      style: {}
    });

    checkoutIframe.startCheckout(ticketId);

    // callback from MCO, triggered if and when the iframe is properly loaded
    checkoutIframe.setCallback('page_loaded', (response: any) => {
      this.mcoIframeLoaded = true;
      this.isMonerisIframeRendered.emit(true);
      this.dataSharingService.IsLoadingEnabled.next(false);
      this.dataSharingService.LoadingLabelText.next('');
    });

    // method that will hanlde all moneris events
    this.monerisCallbackHandling(checkoutIframe, ticketId);
  }

  monerisCallbackHandling(monerisIframeInstance: any, monerisTicketId: string) {
    this.dataSharingService.IsLoadingEnabled.next(true);
    // is triggered when user clicks on "checkout"
    monerisIframeInstance.setCallback('payment_submitted', (response: any) => { 
      this.updateMessageLoading();
      this.dataSharingService.IsLoadingEnabled.next(true);
    });

    // this callback is triggered when the back button from moneris is clicked
    monerisIframeInstance.setCallback('cancel_transaction', (response: any) => {

      this.cancelPayment();

    });

    // triggered when the payment is completed and we get the receipt
    monerisIframeInstance.setCallback('payment_receipt', (recepeitResp: any) => {      
      this.callbackPaymentReceipt(this.monerisTicketId);
 
    });

    // triggered when user clicks on the left arrow (back)
    monerisIframeInstance.setCallback('payment_complete', (response: any) => {
      // this behaviour needs to be confirmed
      this.errorState = true;
      this.paymentFailureFlag.emit(true);
    });

    monerisIframeInstance.setCallback('error_event', (response: any) => {
    });

    monerisIframeInstance.setCallback('page_closed', (response: any) => {
    });
  }

  callbackPaymentReceipt(monerisTicketId: string) {
    this.showCompleteIframe = false;
    this.selectedInvoiceList = this.filterSelectedInvoiceList(this.invoiceList);
    let alternativeEmail: string = '';

    if (this.alternativeEmailHandler[0]) { 
      alternativeEmail = this.alternativeEmailHandler[1];
    }
    
    this.monerisComponent.monerisReceiptRequest(monerisTicketId, this.currentCurrency, this.selectedInvoiceList, this.userEmail, "PayNow", alternativeEmail, this.monerisEnv)
      .subscribe((recepeitResp: any) => {
        const email = this.userEmail;
        this.receipt = this.monerisComponent.monerisReceiptHandling(recepeitResp, this.selectedInvoiceList, email);

        this.errorState = this.receipt?.paymentSuccess;

        if (this.receipt.paymentSuccess) {
          const auPaymentSuccessInfo: AUPaymentSuccess = {
            receiptData: this.receipt,
            showIframe: false,
            paymentSuccessFlag: true
          }
          this.paymentSuccessFlag.emit(auPaymentSuccessInfo);
        }
        else{
          this.showCompleteIframe = true;
          this.selectedPaymentOption = PaymentOption.SAVED_CREDIT_CARD; 
        }
    });
    this.dataSharingService.LoadingLabelText.next('');
    this.dataSharingService.IsLoadingEnabled.next(false);
    this.monerisComponent.monerisUpdateInvoicesDataRequest(this.userEmail, "PayNow", monerisTicketId, this.selectedInvoiceList, this.currentCurrency, this.monerisEnv).subscribe();
  }

  updateMessageLoading() {
    this.translateService.get('MESSAGE_LOADING').subscribe((translation: string) => {
      this.loadText = translation;
    });
    this.dataSharingService.LoadingLabelText.next(this.loadText);
  }

  // Submit payment with Saved Credit Card or EFT
  submitPayment() { 
    switch(this.selectedPaymentOption){
      case PaymentOption.SAVED_CREDIT_CARD:
        //payment with saved card
        this.paymentWithSavedCard();
        break;
      case PaymentOption.MONERIS:
        //dont do anything
        break;  
      case PaymentOption.EFT_REMITTANCE:
          this.paymentwithEFT();
        break;  
    }
    
  }

  paymentWithSavedCard() {
    this.updateMessageLoading();
    this.dataSharingService.IsLoadingEnabled.next(true);
    let alternativeEmail: string = '';

    if (this.alternativeEmailHandler[0]) { 
      alternativeEmail = this.alternativeEmailHandler[1];
    }

    this.monerisIntegrationService.cpsaPayWithSavedCardRequest(this.selectedSavedCard, this.invoiceList, this.currentCurrency, this.totalAmount, this.userEmail, alternativeEmail)
      .subscribe({
        next: (res: any) => {
          if (res.transactionResult == "Approved") {
            const transactionDate = new Date(res.transactionDate + " " + res.transactionTime);
            const paymentDate = transactionDate.toLocaleDateString('en-US', {
              month: 'short',
              day: '2-digit',
              year: 'numeric',
            });

            this.receipt = {
              paymentSuccess: true,
              invoiceList: this.invoiceList,
              emailReceipt: this.emailReceipt,
              ccNumber: res.ccNumber,
              ccType: res.ccType,
              totalPayAmount: this.totalAmount.toString(),
              referenceNumber: res.referenceNo.toString(),
              paymentDate: paymentDate ? paymentDate : ''
            }

            const auPaymentSuccessInfo: AUPaymentSuccess = {
              receiptData: this.receipt,
              showIframe: false,
              paymentSuccessFlag: true
            }
            this.paymentSuccessFlag.emit(auPaymentSuccessInfo);
            this.paymentOptions = [];
            this.dataSharingService.LoadingLabelText.next('');
            this.dataSharingService.IsLoadingEnabled.next(false);
          } else if (res.transactionResult == "Declined") {
            this.errorState = false;
            this.dataSharingService.LoadingLabelText.next('');
            this.dataSharingService.IsLoadingEnabled.next(false);
          }
        },
        error: (error: any) => {
          console.error('Error on payment with saved cc: ', error);
          this.errorState = false;
          this.dataSharingService.LoadingLabelText.next('');
          this.dataSharingService.IsLoadingEnabled.next(false);
        }
      });
    
  }


  paymentwithEFT() {

  const eftData: EFTData = {
    userId: this.obppLoginService.getUserDetails().serviceResponse.userID,
    userEmail: this.obppLoginService.getUserName(),
    userFirstName: this.obppLoginService.getUserDetails().serviceResponse.firstName,
    userLastName: this.obppLoginService.getUserDetails().serviceResponse.lastName,
    paymentResult: "Approved",
    totalAmount: this.totalAmount,
    additionalEmail: this.userEmail,
    paymentType: this.eftLabel,
    paymentCurrency:  this.currentCurrency,
    eftPaymentDate: this.eftPaymentDate,
    eftPayerName: this.eftPayerName,
    eftReferenceNbr: this.eftReferenceNbr,
    invoiceList: this.invoiceList
  }
    
  if (this.alternativeEmailHandler[0]) { 
    eftData.additionalEmail = this.alternativeEmailHandler[1];
  }

  this.emailReceipt = this.userEmail ? this.userEmail : this.obppLoginService.getUserName();

  // TODO: Check what is the content of this.userEmail to validate if and when its to be sent or not

  this.eftService.eftPaymentData(eftData).subscribe((response: any) => {
    this.receiptEft = {
      paymentSuccess: true,
      invoiceList: this.invoiceList,
      emailReceipt: this.emailReceipt,
      totalPayAmount: this.totalAmount.toString(),
      eftPaymentDate: this.eftPaymentDate,
      eftPayerName: this.eftPayerName,
      eftReferenceNbr: this.eftReferenceNbr,
    };

    const auPaymentSuccessInfo: AUPaymentSuccess = {
      receiptEftData: this.receiptEft,
      showIframe: false,
      paymentSuccessFlag: true
    }

    this.paymentSuccessFlag.emit(auPaymentSuccessInfo);
  },
    error => {
      this.errorState = false;
    }
  )
}

  cancelPayment() {
    this.cancelButtonIsClicked.emit(true);
  }

  getEftPaymentInfo(edtInfo: EftRemittance) {
    this.eftPaymentDate = edtInfo.eftPaymentDate;
    this.eftPayerName= edtInfo.eftPayerName;
    this.eftReferenceNbr= edtInfo.eftReferenceNbr;
  }


  getErrorStateFromEft(errorState: boolean) {
    this.eftErrorState = errorState;
  }

  handleSubmitButtonState() {
   
    return !this.optionSelected
      || this.selectedPaymentOption === this.payWithMoneris
      || this.eftErrorState;
  }

  termsAndConditionsHandler(status: boolean) {
    return this.termsAndConditionsAgree = status;
  }

  optionsDisabled(paymentOption: PaymentOption) {
    if (paymentOption !== this.payWithEFT && !this.termsAndConditionsAgree) {
      return true;
    } else {
      return false;
    }
  }

  // Auxiliar method to filter invoice list and get only the selected ones
  filterSelectedInvoiceList(invoiceList: any) { 
    const selectedInvoiceList: any = [];
    invoiceList.forEach((item: any) => {
      if (item.isChecked) {
        selectedInvoiceList.push(item);
      }
    });
    return selectedInvoiceList;
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.isMonerisIframeRendered.emit(false);
    this.mcoIframeLoaded = false;
  }
}


