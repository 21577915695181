export const environment = {
  production: false,
  title: 'Staging AWS - Purolator - Billing Centre',
  apiURL: 'https://stg-be.aws-purolator.com',
  frightSearchEnURL: 'https://cert.freight.purolator.com/scripts/cgiip.exe/WService=wsbroker1/reftrace2.htm?language=english&seskey=&wbtn=BOL&nav=top&prolist=KEY_PIN_NUMBER',
  frightSearchFrURL: 'https://cert.freight.purolator.com/scripts/cgiip.exe/WService=wsbroker1/reftrace2.htm?language=french&seskey=&wbtn=BOL&nav=top&prolist=KEY_PIN_NUMBER',
  courierSearchEnURL: 'https://stg.purolator.com/en/tracker?pin=KEY_PIN_NUMBER',
  courierSearchFrURL: 'https://stg.purolator.com/fr/tracker?pin=KEY_PIN_NUMBER',
  puroInternationalURL: 'https://purolatorqa.ts2000.net/login/quicktrack/KEY_PIN_NUMBER',
  gscsURL: 'https://purolatorqa.ts2000.net/login/quicktrack/KEY_PIN_NUMBER',
  monerisUrl: 'https://stg-be.aws-purolator.com/billingcentre/obpp/service/app/monerisPayment',
  invoiceUrl: 'https://stg-be.aws-purolator.com/billingcentre/obpp/service/app/monerisPayment/invoicepayment'
};
