import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';
import {
  NgbdSortableHeader,
  SortEvent,
} from 'src/app/common/bootstrap-sortable-header/sortable-header.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OBPPModalDialogComponent } from '../../error-component/modal-dialog/modal-dialog.component';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { OBPPAdminQuickPayInvoiceComponent } from 'src/app/common/shared/pop-up-modal/modal-admin-quick-pay-invoice/modal-admin-quick-pay-invoice.component';
import { OBPPQuickPayCPWAService } from 'src/app/services/cpwa-services/quickpay-cpwa.services';
import { CPWAQuickPayData, MonerisReceiptData } from 'src/app/models/obpp-invoice.model';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';
import { LocalStorageService } from 'src/app/services/global/local-storage.service';
import { OBPPAdminQuickPayService } from 'src/app/services/quick-pay/admin-quick-pay.service';
import { TranslateService } from '@ngx-translate/core';
import { MonerisCheckout, ShoppingCartItem } from 'src/app/common/models/monerisCheckout';
import { MonerisIntegrationComponent } from 'src/app/common/shared/moneris-integration/moneris-integration/moneris-integration.component';
import { InvoicePayment } from 'src/app/common/models/invoicePayment';
import { InvoicePaymentService } from 'src/app/services/invoice-payment/invoice-payment.service';
import { OBPPQuickPayService } from 'src/app/services/quick-pay/quick-pay.service';
import { MonerisIntegrationService } from 'src/app/services/moneris-integration/moneris-integration.service';
import { CheckTermsConditionComponent } from '../../check-terms-condition/check-terms-condition.component';

const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Component({
  selector: 'obpp-admin-quick-payment',
  templateUrl: './obpp-admin-quick-payment.component.html',
  styleUrls: ['./obpp-admin-quick-payment.component.scss'],
})
export class OBPPAdminQuickPayment implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers?: QueryList<NgbdSortableHeader>;
  @ViewChild('init__payment') init__payment!: ElementRef;

  @ViewChild('checkAllInoices') checkAllInoices: ElementRef | undefined;
  @ViewChild('selectInvoice') selectInvoice: ElementRef | undefined;
  @ViewChild(CheckTermsConditionComponent) checkTermsConditionComponent!: CheckTermsConditionComponent;

  @ViewChild('cad')
  cad!: ElementRef;

  @ViewChild('usd')
  usd!: ElementRef;
  accountName: any;

  content?: string;
  cpwaPayload: any;

  FromEmployeeCSR: boolean = false;
  paymentSuccessFlag: boolean = false;
  showQuickPay: boolean = false;
  paymentInfo: string = ''; // will be deleted
  showIFrame: boolean = false;
  isCancelClicked: boolean = false;
  declinedMessage: boolean = false;
  amountInvalidFlag: boolean = false;
  emailValid: boolean = false;

  invoiceLength = 0;
  cadInvoiceCount = 0;
  usdInvoiceCount = 0;
  paymentAmount = 0;
  fieldErrorsObj = [];
  QuickPaymentForm: FormGroup;
  errMsgFactory: any;

  quickPaymentData: any = [];
  invoiceList: any = [];
  allInvoiceList: any = [];
  invoiceListGrid: any = [];
  selectedInvoice: string = '';
  loadText: string = '';

  cpwaQuickPayData!: CPWAQuickPayData;
  paymentInformation: any = [];
  paymentURL = '';
  checkAllInvoices: boolean = false;
  unappliedAccountNumber: string = '';
  unAppliedPayAmount: string = '';
  isAccountNumberError: boolean = false;
  isAccountNumberNotExist: boolean = false;
  isPayAmountError: boolean = false;
  paymentCurrency: string = 'CAD';
  cadTotal: number = 0;
  selectNext: boolean = false;
  usdTotal: number = 0;
  paymentDate: any;

  showError: boolean = false;
  termsConditionsAgree = false;
  isCheckboxDisabled = false;

  monerisTicketId: string = '';
  receipt!: MonerisReceiptData;
  errorState: boolean = true;
  errorLoadingMonerisIframe: boolean = false;
  mcoIframeLoaded: boolean = false;
  selectedInvoiceList: any = [];
  additionalEmail: string = '';

  showCompleteIframe: boolean = true;
  monerisEnv: string = '';

  constructor(
    private dataSharingService: DataSharingService,
    private obppLoginService: OBPPLoginService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private obppQuickPayCPWAService: OBPPQuickPayCPWAService,
    private obppAdminQuickPayService: OBPPAdminQuickPayService,
    private localStorageService: LocalStorageService,
    public locstore: LocalStorageService,
    public monerisComponent: MonerisIntegrationComponent,
    private translate: TranslateService,
    public invoicePaymentService: InvoicePaymentService,
    private obppQuickPayService: OBPPQuickPayService,
    private monerisIntegrationService: MonerisIntegrationService
  ) {
    this.QuickPaymentForm = this.fb.group({
      invoiceType: ['', Validators.required],
      accountNumbers: '',
      invoiceNumbers: ['', Validators.required],
      paymentAmount: ['', Validators.required],
      emailAddress: [
        '',
        Validators.pattern(
          '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,7}$'
        ),
      ],
    });
    this.paymentURL = this.obppLoginService.getCPWAPaymentURL();
    this.translate.get('MESSAGE_LOADING').subscribe((translation: string) => {
      this.loadText = translation;
    });
  }

  ngOnInit() {
    this.monerisIntegrationService.getMonerisEnvironment().subscribe({
      next: (env: any) => { 
        this.monerisEnv = env.response;
      },
      error: (error: any) => { 
        this.monerisEnv = 'prod';
      }
    });

    this.showQuickPay = true;
    this.quickPaymentData = [];
    this.selectedInvoice = '';
    this.showIFrame = false;
    this.declinedMessage = false;
    this.paymentCurrency = 'CAD';
    this.popUpQuickPay();
    if (this.quickPaymentData?.object?.invoices) {
      this.invoiceList = this.quickPaymentData?.object?.invoices;
      this.paymentAmount = 0;
      this.invoiceLength = 0;
      this.invoiceList.forEach((item: any) => {
        item.isChecked = false;
        item.paymentAmount = 0;
        if (this.selectedInvoice == item.invoiceNumber) {
          item.isChecked = true;
          item.paymentAmount = parseFloat(item.balanceDue);
          this.paymentAmount += parseFloat(item.balanceDue);
          this.invoiceLength++;
        } else {
          item.paymentAmount = 0;
        }
      });
      this.allInvoiceList = this.cloneObject(this.invoiceList);
    } else {
      this.invoiceList = [];
      this.allInvoiceList = [];
      this.invoiceLength = 0;
      this.paymentAmount = 0;
    }
  }

  cloneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers?.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction || column) {
      this.invoiceList = [...this.invoiceList].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  cancelConfirmation() {
    let tit = 'CONFIRMATION';
    let errMsg = 'QUICK_PAY_CANCEL_TEXT_CONFIRMATION';
    this.openModal(tit, errMsg, 'YES', 'NO', 'warning');
  }

  loadIFrame() {
    this.showIFrame = true;
  }

  popUpQuickPay() {
    this.openQuickPayPopup(
      'QUICK_PAY_TEXT',
      '',
      'CONTINUE_TEXT',
      'CANCEL_TEXT',
      'warning'
    );
  }

  payAnotherInvoiceAfterPayment() {
    this.ngOnInit();
    this.QuickPaymentForm.get('emailAddress')?.setValue('');
    this.paymentSuccessFlag = false;
  }

  registerforBilling() {
    this.router.navigateByUrl('/register');
  }

  payAnotherInvoice() {
    this.router.navigateByUrl('/manageusers');
  }

  openModal(
    title: string,
    errorMsg: string,
    firstButtonLabel: any,
    secondButtonLabel: any,
    modalType: string
  ) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      this.returnToHome();
    });
  }

  returnToHome() {
    this.router.navigateByUrl('/manageusers');
    this.dataSharingService.activeIdString.next('/manageusers');
  }

  openQuickPayPopup(
    title: string,
    errorMsg: string,
    firstButtonLabel: any,
    secondButtonLabel: any,
    modalType: string
  ) {
    const modalRef = this.modalService.open(OBPPAdminQuickPayInvoiceComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;
    modalRef.componentInstance.accountNumber = this.allInvoiceList.map(
      (item: any) => item.invoiceNumber
    );
    modalRef.componentInstance.invoiceNumber = this.allInvoiceList.map(
      (item: any) => item.accountNumber
    );

    modalRef.componentInstance.successAction.subscribe((newList: any) => {
      if (newList?.object?.accountList?.length > 0) {
        newList.object.accountList.forEach((account: any) => {
          if (account.invoices?.length > 0) {
            let invoices = account.invoices;
            invoices.forEach((inv: any) => {
              inv.isChecked = false;
              inv.paymentAmount = 0;
            });
            this.allInvoiceList.push(...invoices);
            this.invoiceList.push(...invoices);
          }
        });
      }
      this.checkAllInvoices = false;
    });
  }

  searchFunc() {
    this.invoiceList = this.cloneObject(this.allInvoiceList);
  }

  updatePaymentAmount(event: any) {
    this.invoiceLength = 0;
    this.paymentAmount = 0;
    this.invoiceList.forEach((item: any) => {
      item.paymentAmount =
        item.paymentAmount == '' || item.paymentAmount === null
          ? 0
          : item.paymentAmount;
      if (item.isChecked) {
        this.paymentAmount += parseFloat(item.paymentAmount);
        this.invoiceLength++;
      }
    });
  }

  updatePaymentAmountFromSelect(event: any, i: any) {
    this.invoiceLength = 0;
    this.paymentAmount = 0;
    if (this.invoiceList[i].isChecked) {
      this.invoiceList[i].paymentAmount = this.invoiceList[i].balanceDue;
    } else {
      this.checkAllInvoices = false;
      this.invoiceList[i].paymentAmount = 0;
    }
    this.invoiceList.forEach((item: any) => {
      item.paymentAmount =
        item.paymentAmount == '' || item.paymentAmount === null
          ? 0
          : item.paymentAmount;
      if (item.isChecked) {
        this.paymentAmount += parseFloat(item.paymentAmount);
        this.invoiceLength++;
      }
    });
    if (this.invoiceLength === this.invoiceList.length) {
      this.checkAllInvoices = true;
      this.selectNext = false;
    } else if (this.invoiceLength === 0) {
      this.selectNext = true;
    } else {
      this.selectNext = false;
    }
  }

  checkAllInvoice(event: any) {
    event.preventDefault();
    if (this.checkAllInoices) {
      if (this.checkAllInoices.nativeElement.checked == true) {
        this.checkAllInvoices = false;
        this.selctAllInvoices(event);
      } else if (this.checkAllInoices.nativeElement.checked == false) {
        this.checkAllInvoices = true;
        this.selctAllInvoices(event);
      }
      this.checkAllInoices.nativeElement.checked =
        !this.checkAllInoices.nativeElement.checked;
    }
  }
  checkOneInvoice(event: any, index: any, invoiceNumber: any, isChecked: any) {
    event.preventDefault();
    if (this.selectInvoice) {
      this.invoiceList.forEach((invoice: any) => {
        if (invoice.invoiceNumber === invoiceNumber) {
          if (isChecked) {
            invoice.isChecked = false;
            this.updatePaymentAmountFromSelect(event, index);
          } else if (!isChecked) {
            invoice.isChecked = true;
            this.updatePaymentAmountFromSelect(event, index);
          }
        }
      });
      this.selectInvoice.nativeElement.checked =
        !this.selectInvoice.nativeElement.checked;
    }
  }

  selctAllInvoices(event: any) {
    this.invoiceLength = 0;
    this.paymentAmount = 0;

    this.invoiceList.forEach((item: any) => {
      if (this.checkAllInvoices) {
        item.isChecked = true;
        if (parseFloat(item.paymentAmount) <= 0) {
          item.paymentAmount = parseFloat(item.balanceDue);
        }
        this.invoiceLength++;
        this.paymentAmount += parseFloat(item.paymentAmount);
      } else {
        item.isChecked = false;
        item.paymentAmount = 0;
        this.invoiceLength = 0;
        this.paymentAmount = 0;
      }
    });
    if (this.checkAllInvoices) {
      this.selectNext = false;
    } else if (!this.checkAllInvoices) {
      this.selectNext = true;
    }
  }

  updateOnlyAmount() {
    this.invoiceList.forEach((item: any) => {
      item.paymentAmount =
        item.paymentAmount == '' || item.paymentAmount === null
          ? 0
          : item.paymentAmount;
      if (
        parseFloat(item.paymentAmount) > parseFloat(item.balanceDue) &&
        item.invoiceNumber != '888888888'
      ) {
        item.paymentAmount = 0;
      }
    });
  }

  filterInvoiceList(invoiceList: any): ShoppingCartItem[] {
    const cartItem: ShoppingCartItem[] = [];

    invoiceList.forEach((item: any) => {
      if (item.isChecked) {
        cartItem.push({
          unit_cost: item.paymentAmount,
          quantity: '1',
          description:
            '{' +
            '"account":"' +
            item.accountNumber +
            '","invoice":"' +
            item.invoiceNumber +
            '","currency":"' +
            item.invoiceCurrency +
            '"}',
        });
      }
    });

    return cartItem;
  }

  // Auxiliar method to filter invoice list and get only the selected ones
  filterSelectedInvoiceList(invoiceList: any) { 
    const selectedInvoiceList: any = [];
    invoiceList.forEach((item: any) => {
      if (item.isChecked) {
        selectedInvoiceList.push(item);
      }
    });
    return selectedInvoiceList;
  }

  termsAndConditionsHandler(status: boolean) { 
    this.termsConditionsAgree = status;
    if (status) {
      this.loadMonerisIframe();
      this.errorState = true;
      this.mcoIframeLoaded = true;
      this.showCompleteIframe = true;
      this.mcoIframeLoaded = true;
    }
    else{
      this.termsConditionsAgree = false;
      this.showCompleteIframe = false;
      this.mcoIframeLoaded = false;
      this.checkTermsConditionComponent.setShowError(true);
    }
  }

  loadMonerisIframe() {
    this.isCancelClicked = false;
    if (this.invoiceLength > 0 && this.paymentAmount > 0) {
      this.dataSharingService.IsLoadingEnabled.next(true);
      this.updateMessageLoading();

      // FIX ME: this payload needs to meu change, property "environement" is point out to QA.
      // Fix must be done before the deployment to PROD env.
      const monerisPayload: MonerisCheckout = {
        txn_total: this.paymentAmount.toFixed(2).toString(),
        environment: this.monerisEnv,
        language: this.localStorageService.get('lang'),
        contact_details: {
          email: this.QuickPaymentForm.get('emailAddress')?.value,
        },
        cart: {
          items: this.filterInvoiceList(this.invoiceList),
        },
        currency: this.paymentCurrency,
      };

      this.showIFrame = true;
      this.monerisComponent
        .monerisPreloadRequest(monerisPayload).subscribe({
          next: (response) => {
            if (response.response.success === 'false') {
              this.errorLoadingMonerisIframe = true;
              this.dataSharingService.IsLoadingEnabled.next(false);
              this.dataSharingService.LoadingLabelText.next('');
            }

            if (response.response.ticket) {
              this.monerisTicketId = response.response.ticket;
              this.initMonerisIframe(response.response.ticket);
            }
          },
          error: (error) => {
            console.error('Error on moneris preload request: ', error);
            this.errorLoadingMonerisIframe = true;
            this.dataSharingService.IsLoadingEnabled.next(false);
            this.dataSharingService.LoadingLabelText.next('');
           }

        });
    }
  }

  updateMessageLoading() {
    this.translate.get('MESSAGE_LOADING').subscribe((translation: string) => {
      this.loadText = translation;
    });
    this.dataSharingService.LoadingLabelText.next(this.loadText);
  }

  //Function we should use to store the checkbox value among other data
  saveInvoicePayment() {
    const InvoicePayment: InvoicePayment = {
      useremail: this.QuickPaymentForm.get('emailAddress')?.value,
      check_term_cond: this.termsConditionsAgree,
    };

    this.invoicePaymentService
      .saveInvoicePayment(InvoicePayment)
      .subscribe((response) => {
      });
  }

  initMonerisIframe(ticketId: any) {
    // Configure Moneris Checkout
    const checkoutIframe = new monerisCheckout();

    // FIX ME: Should be changged to PROD before the deployment to PROD env.
    checkoutIframe.setMode(this.monerisEnv);

    checkoutIframe.setCheckoutDiv('monerisCheckout', {
      ticketId,
      style: {},
    });

    checkoutIframe.startCheckout(ticketId);

    // callback from MCO, triggered if and when the iframe is properly loaded
    checkoutIframe.setCallback('page_loaded', (response: any) => {
      this.dataSharingService.IsLoadingEnabled.next(false);
      this.dataSharingService.LoadingLabelText.next('');
      this.mcoIframeLoaded = true;
    });

    // method that will hanlde all moneris events
    this.monerisCallbackHandling(checkoutIframe, ticketId);
  }

  monerisCallbackHandling(monerisIframeInstance: any, monerisTicketId: string) {
    // is triggered when user clicks on "checkout"
    monerisIframeInstance.setCallback('payment_submitted', (response: any) => {
      this.dataSharingService.IsLoadingEnabled.next(true);
    });

    // this callback is triggered when the back button from moneris is clicked
    monerisIframeInstance.setCallback('cancel_transaction', (response: any) => {
      this.showIFrame = false;
      this.showQuickPay = true;
      this.termsConditionsAgree = false;
      this.mcoIframeLoaded = false;
    });

    // triggered when the payment is completed and we get the receipt
    monerisIframeInstance.setCallback('payment_receipt', (recepeitResp: any) => {
        this.callbackPaymentReceipt(this.monerisTicketId);
      }
    );

    // triggered when user clicks on the left arrow (back)
    monerisIframeInstance.setCallback('payment_complete', (response: any) => {
      // this behaviour needs to be confirmed
      this.showIFrame = false;
    });

    monerisIframeInstance.setCallback('error_event', (response: any) => {});

    monerisIframeInstance.setCallback('page_closed', (response: any) => {});
  }

  callbackPaymentReceipt(monerisTicketId: string) {
    this.showCompleteIframe = false;  
    let accountEmail = this.obppLoginService.getUserName()
    let additionalEmail = this.QuickPaymentForm.get('emailAddress')?.value;
    
    this.selectedInvoiceList = this.filterSelectedInvoiceList(this.invoiceList);

    // This change is required, we changed the dateDue and invoiceDate to timestamp
    const adjustedInvoiceList = this.invoiceListAdjusts(this.selectedInvoiceList);

    this.monerisComponent
      .monerisReceiptRequest(monerisTicketId, this.paymentCurrency, this.selectedInvoiceList, accountEmail, "QuickPay-E", additionalEmail, this.monerisEnv)
      .subscribe((recepeitResp: any) => {
        this.receipt = this.monerisComponent.monerisReceiptHandling(
          recepeitResp,
          this.invoiceList,
          accountEmail
        );
        this.errorState = this.receipt?.paymentSuccess;

        if (this.receipt.paymentSuccess) {
          this.showIFrame = false;
          this.showQuickPay = false;
          this.paymentSuccessFlag = true;
        }
        else{
          this.termsAndConditionsHandler(false);
          this.termsConditionsAgree = false;
          this.errorState = false;
        }
        this.dataSharingService.IsLoadingEnabled.next(false);
      });
    
    this.monerisComponent
      .monerisUpdateInvoicesDataRequest(
        accountEmail,
        'QuickPay-E',
        monerisTicketId,
        adjustedInvoiceList,
        this.paymentCurrency,
        this.monerisEnv
      )
      .subscribe();
  }

  invoiceListAdjusts(invoiceList: any) {
    if (invoiceList.length) {
      invoiceList.forEach((invoice: any) => {
        if (invoice.dueDate) {
          invoice.dueDate = Date.parse(invoice.dueDate);
        }

        if (invoice.invoiceDate) {
          invoice.invoiceDate = Date.parse(invoice.invoiceDate);
        }
      })
      return invoiceList;
    }
  }

  backToInvoiceList() {
    this.errorLoadingMonerisIframe = false;
    this.showIFrame = false;
    this.showQuickPay = true;
    this.termsConditionsAgree = false;
    this.errorState = true;
    this.showCompleteIframe = true;
  }

  parseDateToLang(d: string) {
    let year = parseInt(d.substring(0, 4));
    let mon = parseInt(d.substring(4, 6)) - 1;
    let day = parseInt(d.substring(6, 8));
    let hr = parseInt(d.substring(8, 10));
    let min = parseInt(d.substring(10, 12));
    let sec = parseInt(d.substring(12));

    let dt = new Date(year, mon, day, hr, min, sec);

    return dt;
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent): void {
    this.isCancelClicked = false;
    if (event.data.event === 'cpwaProcessing') {
      this.dataSharingService.isPaymentInProgress.next(true);
    }

    if (event.data.event === 'cpwaProcessed') {
      this.dataSharingService.isPaymentInProgress.next(false);
    }

    if (event.data.event === 'cpwaCommit') {
      let payload;
      let property: keyof typeof event.data;
      for (property in event.data) {
        if (property === 'payload') {
          payload = event.data[property];
        }
      }

      let quickPaySumitpayment = {
        paymentCurrency: this.paymentCurrency,
        createMethod: this.cpwaQuickPayData.createMethod,
        additionalEmailId: this.cpwaQuickPayData.additionalEmailId,
        encryptedPayload: this.cpwaPayload,
        encryptedPaymentResult: payload,
        paymentType: this.cpwaQuickPayData.paymentType,
        totalPayAmount: this.cpwaQuickPayData.totalPayAmount,
        invoiceList: this.cpwaQuickPayData.invoiceList,
      };

      this.obppQuickPayCPWAService
        .submitPaymentToCpwa(quickPaySumitpayment)
        .subscribe((paymentres: any) => {
          this.paymentInfo = '';
          this.paymentInformation = [];
          if (paymentres['serviceResponse']['type'] == 'success') {
            this.paymentInformation = paymentres['serviceResponse']['object'];
            this.showIFrame = false;
            this.showQuickPay = false;
            this.paymentSuccessFlag = true;
            let paymentDate = this.paymentInformation?.paymentDateTime.slice(
              0,
              8
            );
            const year = paymentDate.slice(0, 4);
            const month = paymentDate.slice(4, 6);
            const date = paymentDate.slice(6, 8);
            paymentDate = new Date(year, month - 1, date);
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.paymentDate = paymentDate.toLocaleDateString('en-US', {
              timezone: timezone,
              month: 'short',
              day: '2-digit',
              year: 'numeric',
            });
          } else {
            this.isCancelClicked = true;
            this.showIFrame = false;
            this.showQuickPay = true;
          }
        });
    }
  }

  printPage() {
    let css = '@page { size: landscape; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.media = 'print';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    window.print();
  }

  validateUnAppliedData() {
    this.isPayAmountError = false;
    this.isAccountNumberError = false;
    if (this.unappliedAccountNumber == '') {
      this.isAccountNumberError = true;
    } else if (this.unAppliedPayAmount == '') {
      this.isPayAmountError = true;
    }
  }

  addUnappliedAccount() {
    this.isAccountNumberNotExist = false;
    this.validateUnAppliedData();
    if (!this.isPayAmountError && !this.isAccountNumberError) {
      this.dataSharingService.IsLoadingEnabled.next(true);
      this.isAccountNumberError = false;
      let totalPaymentAmount = 0;
      this.obppAdminQuickPayService
        .searchByAccountOrInvoice({
          accountNumber: this.unappliedAccountNumber,
        })
        .subscribe(
          (res) => {
            if (res.serviceResponse.type == 'success') {
              res.serviceResponse.object.forEach((item: any) => {
                if (item.accountNumber != null && item.accountNumber != '') {
                  item.invoiceAmount = 0;
                  item.balanceDue = parseFloat(this.unAppliedPayAmount);
                  item.paymentAmount = parseFloat(this.unAppliedPayAmount);
                  item.payAmount = parseFloat(this.unAppliedPayAmount);
                  item.invoiceNumber = '888888888';
                  item.invoiceCurrency = this.paymentCurrency;
                  item.isChecked = true;

                  totalPaymentAmount += item.paymentAmount;
                  this.allInvoiceList.push(item);
                  this.searchFunc();
                } else {
                  this.isAccountNumberNotExist = true;
                }
              });
              this.updatePaymentAmount(totalPaymentAmount);
            } else {
              this.isAccountNumberError = true;
            }
            this.unAppliedPayAmount = '';
            this.unappliedAccountNumber = '';
            this.dataSharingService.IsLoadingEnabled.next(false);
          },
          (error) => {
            this.isAccountNumberError = true;
            this.dataSharingService.IsLoadingEnabled.next(false);
          }
        );
    }
  }

  clearResultsFromUnapplied() {
    this.unAppliedPayAmount = '';
    this.unappliedAccountNumber = '';
  }

  setPaymentCurrency(paymentType: string) {
    if (paymentType === 'cad') {
      this.cad.nativeElement.checked = true;
      this.paymentCurrency = 'CAD';
      if (this.cadTotal === 0) {
        this.selectNext = true;
      } else {
        this.selectNext = false;
      }
    } else if (paymentType === 'usd') {
      this.usd.nativeElement.checked = true;
      this.paymentCurrency = 'USD';
      if (this.usdTotal === 0) {
        this.selectNext = true;
      } else {
        this.selectNext = false;
      }
    }
  }

  getSelectedCADTotal() {
    let tot = 0;
    let count = 0;
    this.invoiceList.forEach((item: any) => {
      if (item.invoiceCurrency === 'CAD' && item.isChecked) {
        count++;
        tot += item.balanceDue ? parseFloat(item.paymentAmount) : 0;
      }
    });
    this.cadTotal = tot;
    this.cadInvoiceCount = count;
    return tot;
  }

  getSelectedUSDTotal() {
    let tot = 0;
    let count = 0;
    this.invoiceList.forEach((item: any) => {
      if (item.invoiceCurrency === 'USD' && item.isChecked) {
        count++;
        tot += item.balanceDue ? parseFloat(item.paymentAmount) : 0;
      }
    });
    this.usdTotal = tot;
    this.usdInvoiceCount = count;
    return tot;
  }

  isEmailAddressValid(): boolean {
    const emailControl = this.QuickPaymentForm.get('emailAddress');
    this.additionalEmail = this.QuickPaymentForm.get('emailAddress')?.value;
    return emailControl ? emailControl.valid && emailControl.dirty : false;
  }

  disabledNext() {
    const invoice = this.invoiceList.filter(
      (invoice: { isChecked: boolean }) => invoice.isChecked == true
    );
    this.emailValid = this.isEmailAddressValid();

    if (invoice.length > 0 && this.isTotalAmountValid) {
      return false;
    }

    return true;
  }

  onCheckboxChange() {
    this.termsConditionsAgree = !this.termsConditionsAgree;
  }

  get isTotalAmountValid() {
    return (
      (this.paymentCurrency === 'CAD' && this.cadTotal > 0) ||
      (this.paymentCurrency === 'USD' && this.usdTotal > 0)
    );
  }
}
