 <input class="form-control " [ngClass]="errorState ? 'inputDatePickerError' : 'inputDatePicker'" placeholder="YYYY-MM-DD" name="date" 
        ngbDatepicker #c="ngbDatepicker"  (click)="c.toggle()" (dateSelect)="onDateSelect($event)" [(ngModel)]="model" (change)="onDateChange($event)" 
       [maxDate]="maxDate" [minDate]="minDate" [autoClose]="true"  [footerTemplate]="fromDateTemplate">
    <ng-template #fromDateTemplate>
        <div class="mb-2">
            <div class="d-flex justify-content-around" role="group">
                <button class="btn btn-primary btn-sm me-2 ms-2" (click)="setToday()">{{ 'TODAY_TEXT' | translate }}</button>
                <button class="btn btn-primary cta-blue me-2" (click)="clearDate()">{{ 'CLEAR_TEXT' | translate }}</button>
                <button class="btn btn-primary btn-sm me-2" (click)="c.close()">{{ 'EFT_GUIDELINES_CLOSE' | translate }}</button>
            </div>
        </div>
    </ng-template>
