<div class="modal-header" [ngClass]="modalType == 'error' ? 'error' : modalType == 'warning' ? 'warning' : 'success'">
  <h3 class="modal-title w-100 text-center">{{my_modal_title | translate}}</h3>
</div>
<div class="modal-body">
  <p> {{my_modal_content | translate}}</p>

  <form [formGroup]="forgotForm">
    <div class="input-group">
      <div class="form-floating user-input p-bottom-24">
          <input type="email" class="form-control" formControlName="useremail_forgot" id="email"
              placeholder="{{'LOGIN_EMAIL_PLACEHOLDER_KEY' | translate}}" required=true 
              aria-label="email" aria-describedby="basic-addon1" 
              [class]="forgotForm.get('email')?.invalid && (forgotForm.get('email')?.dirty
              ) ? 'form-control is-invalid' : 'form-control'">
          <label for="email">{{'LOGIN_EMAIL_PLACEHOLDER_KEY' | translate}}</label>
          <div class="w-100" *ngIf="forgotForm.get('useremail_forgot')?.invalid && forgotForm.get('useremail_forgot')?.dirty">
            <span class="error-block">{{ 'EMAIL_VALID' | translate }}</span>
        </div>
      </div>
  </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button [disabled]="forgotForm.get('useremail_forgot')?.invalid" type="button" *ngIf="firstButtonLabel" class="btn btn-primary btn-block primary-btn"
    (click)="successActionFunc()">{{firstButtonLabel | translate}}</button>
  <button type="button" *ngIf="secondButtonLabel" class="btn btn-primary cta-blue info-btn"
    (click)="cancelActionFunc()">{{secondButtonLabel | translate}}</button>
</div>