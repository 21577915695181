<div id="ngb-nav-3-panel">
<div *ngIf="!declinedMessage && !paymentSuccessFlag">
    <div>
        <div class="ml-20 mt-20">
            <h2 class="txt-puro-blue">{{ "WEEKLY_PGM" | translate }}</h2>
        </div>
    </div>
    <div>
        <table class="table table-striped" aria-describedby="tableDescription">
            <thead class="panel-table">
                <tr>
                    <th scope="col" class="col-md-2"><label tabindex="0">{{ "ACCOUNT_TEXT_AUTOPAY" | translate }}</label></th>
                    <th scope="col" class="col-md-2">
                        <label tabindex="0">{{ "STATUS_TEXT" | translate }}
                        <img class="icon" src="assets/images/icon-tooltip.svg"
                            alt="{{ 'AUTOPAY_STATUS_TOOLTIP_TEXT' | translate }}" tabindex="0"
                            ngbTooltip="{{ 'AUTOPAY_STATUS_TOOLTIP_TEXT' | translate }}" />
                        </label>
                    </th>
                    <th scope="col" class="col-md-2">
                        <label tabindex="0">{{ "ACTIVATION_DATE_UPDATED_TEXT" | translate }}
                        <img class="icon" src="assets/images/icon-tooltip.svg"
                            alt="{{ 'ACTIVATION_DATE_TOOLTIP_TEXT' | translate }}" tabindex="0"
                            ngbTooltip="{{ 'ACTIVATION_DATE_TOOLTIP_TEXT' | translate }}" />
                        </label>
                    </th>
                    <th scope="col" class="col-md-2 text-center">
                        <label tabindex="0">{{ "SEND_NOTIFICATION_FOR_SUCCESSFUL_TRANSACTIONS" | translate }}
                        <img class="icon" src="assets/images/icon-tooltip.svg"
                            alt="{{ 'SEND_NOTIFICATION_TOOLTIP_TEXT' | translate }}" tabindex="0"
                            ngbTooltip="{{ 'SEND_NOTIFICATION_TOOLTIP_TEXT' | translate }}" />
                        </label>
                    </th>
                    <th scope="col" class="col-md-3">
                        <label tabindex="0">{{ "SEND_NOTIFICATION_COPY" | translate }}
                        <img class="icon" src="assets/images/icon-tooltip.svg"
                            alt="{{ 'CONFIRMATION_MAIL_TOOLTIP_TEXT' | translate }}" tabindex="0"
                            ngbTooltip="{{ 'CONFIRMATION_MAIL_TOOLTIP_TEXT' | translate }}" />
                        </label>
                    </th>
                    <th scope="col" class="col-md-1">
                        <label tabindex="0" [attr.aria-label]="'REMOVE_KEY' | translate">{{ "REMOVE_KEY" | translate }}</label>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="!creditCardList || creditCardList.length === 0">
                <tr>
                    <td colspan="6" class="text-center">
                        {{ "NO_INVOICES_MATCHING_ERROR_OPEN_INVOICES" | translate }}
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr *ngFor="let creditcard of creditCardList">
                    <td colspan="7" class="p-0">
                        <ngb-accordion>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <label class="col-sm-3 title-label-text">{{ creditcard.ccType }} {{
                                        creditcard.ccMask }}</label>
                                    <span class="col-sm-1 remove-account">
                                        <img title="{{ 'REMOVE_ACCOUNT' | translate }}" class="icon"
                                            src="assets/images/blueclose.svg" alt="Remove Access" (click)="
                                        this.removeAutoPayAccount(creditcard.creditCardId, '-1')" tabindex="0" (keydown.enter)="this.removeAutoPayAccount(creditcard.creditCardId, '-1')"/>
                                    </span>
                                </ng-template>
                                <ng-template ngbPanelContent *ngIf="this.autopayList != null">
                                    <div *ngFor="let autopayaccount of autopayList; index as i">
                                        <div *ngIf="autopayaccount.creditcardid === creditcard.creditCardId">
                                            <div
                                                [class]="i%2 !== 0 && autopayList.length>1? 'row panel-card-tabel' : 'row panel-card-tabel odd-color'">
                                                <div class="col-md-2 d-flex align-items-center">
                                                    <div>
                                                        <span style="vertical-align: middle;">
                                                            {{ autopayaccount.accountNumber }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 d-flex align-items-center" tabindex="0">
                                                    <span>
                                                        {{
                                                        getDisplayobppAutoPayAccountStatus(
                                                        autopayaccount.obppAutoPayAccountStatus
                                                        ) | translate}}
                                                    </span>
                                                </div>
                                                <div class="col-md-2 d-flex align-items-center justify-content-center" tabindex="0">
                                                    <span>
                                                        {{ autopayaccount.targetActionDateFrontEnd }}
                                                    </span>
                                                </div>
                                                <div class="col-md-2 d-flex align-items-center justify-content-center" tabindex="0">
                                                    <span>
                                                        <input name="autoPayFlag" type="checkbox" [checked]="autopayaccount.sendSuccessfullNotificationInd" disabled />
                                                    </span>
                                                </div>
                                                <div class="col-md-3 d-flex align-items-center" tabindex="0">
                                                    <span>
                                                        {{ autopayaccount.carbonCopyEmailAddress }}
                                                    </span>
                                                </div>
                                                <div class="col-md-1 d-flex justify-content-center align-items-center">
                                                    <span>
                                                        <img title="{{ 'REMOVE_ACCOUNT' | translate }}" class="icon" tabindex="0"
                                                            src="assets/images/blueclose.svg" alt="Remove Access" (keydown.enter)="this.removeAutoPayAccount(
                                                                creditcard.creditCardId,
                                                                autopayaccount.accountNumber
                                                                )"
                                                            (click)="
                                                            this.removeAutoPayAccount(
                                                            creditcard.creditCardId,
                                                            autopayaccount.accountNumber
                                                            )" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="showNextPage && !declinedMessage && !paymentSuccessFlag">
    <div class="mt-5">
        <div class="row" >
            <div class="col-md-6" tabindex="0">
                <div class="d-flex flex-column">
                    <label id="labelAutoPay" class="label-autopay-text" tabindex="0">{{ "AUTO_PAY_ACCOUNT_TEXT" | translate }}</label>
                    <ul>
                        <li class="fs-14" *ngFor="let accountDetail of accountDetails.controls" tabindex="0">
                            {{ accountDetail.value.accountNumberOriginal ? accountDetail.value.accountNumberOriginal : accountDetail.value.accountNumber }}
                        </li>
                    </ul>
                    
                    <button class="btn btn-primary btn-block primary-btn" id="goBack" tabindex="0" (click)="showNextPageAction()" (keydown.enter)="showNextPageAction()" >
                        {{ "GO_BACK_TEXT" | translate }}
                    </button>
                </div>
            </div>
            <div class="col-md-5" >
                <div id="paymentModal" class="modal-payment">
                    <!-- Modal content -->
                    <div class="modal-payment-content">
                        <div class="form-group">
                            <div class="section" id="paymentContainer">
                                {{ "PAYMENT_IN_PROGRESS" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>